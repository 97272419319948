import React from "react";

export default function SecondPage() {
    return (
        <section
            data-aos="zoom-in-up"
            data-aos-delay="100"
            data-aos-duration="1000"
            className="md:my-10 md:py-0 py-[15rem] w-full md:text-black text-white z-10 gap-10 flex flex-col items-center justify-center"
            style={{
                backgroundImage: `url(./images/uepi05jkfw2ptfccqe0d.webp)`,
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
        >
            <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 -z-10" />
            <div className="absolute top-0 left-0 hidden w-full h-full bg-white md:block -z-10" />
            <div className="flex flex-col w-4/5 text-center text-white md:hidden md:w-1/2 sm:text-xl">
                <h1 className="text-2xl font-bold">
                    Tropical Dreams Come True
                </h1>
                <div>
                    Prepare to be captivated by the enchanting beauty and
                    incredible sights that await you in Puerto Princesa,
                    Palawan!
                </div>
            </div>
            <div className="relative grid text-center md:w-3/5 place-items-center">
                <img
                    className="shadow-xl md:rounded-2xl md:block"
                    src="./images/uepi05jkfw2ptfccqe0d.webp"
                    alt="dafsdf"
                />
            </div>
        </section>
    );
}
