import React from "react";

export default function Footer() {
    return (
        <footer className="w-full bg-[#222222] text-white flex flex-col md:flex-row gap-5 py-40">
            <div className="w-full px-10 py-20 lg:w-1/2">
                <h1 className="mb-10 text-4xl font-bold">
                    Get in touch with us!
                </h1>
                <div className="flex flex-col gap-2">
                    <input className="p-2" type="text" placeholder="Name" />
                    <input className="p-2" type="text" placeholder="Email" />
                    <textarea
                        className="p-2"
                        placeholder="Message"
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                    ></textarea>
                </div>
            </div>
        </footer>
    );
}
