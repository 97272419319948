var data = require('../package.json')
var _host = "";
_host = "http://localhost:8080";
let commondata;
if (data.mode == "dev") {
    commondata = {
        fileuploadurl: "",
        guesttemplate: "",
        baseurl: "https://tourism-demo-api.smartpay.ph/public/index.php/api/",
        
    }
} else {
    commondata = {  
        fileuploadurl: "",
        guesttemplate: "",
        baseurl: "https://tourism-demo-api.smartpay.ph/public/index.php/api/"
    }
}

export default commondata
