import React from "react"

export default function LandingPage() {
    return (
        <main className="relative flex items-center justify-center w-full h-screen bg-no-repeat bg-cover bg-home-image">
            <div className="absolute w-full h-full backdrop-brightness-50" />
            <div className="flex flex-col w-full text-4xl text-center text-white sm:text-5xl md:text-8xl">
                <h1
                    data-aos="zoom-in"
                    data-aos-delay="500"
                    data-aos-duration="1000"
                    className="text-[5rem] font-bold drop-shadow-lg"
                >
                    Experience
                </h1>
                <h2
                    data-aos="zoom-in"
                    data-aos-delay="500"
                    data-aos-duration="1000"
                    className="font-bold text-[7rem] puerto-header drop-shadow-lg"
                >
                    Puerto Princesa
                </h2>
                <p
                    data-aos="fade-up"
                    data-aos-delay="500"
                    data-aos-duration="1000"
                    className="text-lg drop-shadow-lg"
                >
                    Where Nature's Masterpieces Come to Life
                </p>
            </div>
            <div className="hidden custom-shape-divider-bottom-1685939409 md:block">
                <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                >
                    <path
                        d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                        className="shape-fill"
                    ></path>
                </svg>
            </div>
        </main>
    )
}
