import React from 'react'

export default function FourthPage() {
    return (
        <section
                    data-aos='fade-up'
                    data-aos-delay="100"
                    data-aos-duration="1000"
                    className='bg-journey-image flex flex-col items-center justify-end text-white bg-cover bg-no-repeat md:mt-20 w-full relative'
                >
                    <div className='absolute backdrop-brightness-50 h-full w-full'/>
                    <div className="custom-shape-divider-top-1686037844 hidden md:block">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                        </svg>
                    </div>
                    <div className='pt-[40rem] pb-40 text-center flex flex-col gap-5 px-2 items-center'>
                        <h1 className='text-4xl font-bold drop-shadow-lg'>Journey to Tranquility</h1>
                        <p className='drop-shadow-lg w-[70%]'>Find Peace and Relaxation in Puerto Princesa, Palawan's Idyllic Retreats!</p>
                    </div>
                </section>
    )
}
