import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
export default function Nav(props) {
    // const [className, setClassName] = useState(props.className)
    const [open, setOpen] = useState(false);

    return (
        <div
            className={`z-20 flex justify-center items-center w-full bg-white drop-shadow-lg ${
                props.className === "static" ? "" : "fixed"
            }`}
        >
            <div className="flex flex-row items-center w-full mx-5 text-xs xl:mx-40 md:mx-20">
                <div className="cursor-pointer">
                    <Link to="/Home">
                        <img className="w-10 md:w-20" src={logo} alt="logo" />
                    </Link>
                </div>
                <div
                    className={`absolute top-0 transition-all ease-in-out w-4/5 h-screen flex flex-col pt-10 gap-5 ml-auto text-xl bg-white md:p-0 md:justify-end md:h-fit md:bg-none md:static lg:text-lg md:flex-row lg:w-1/2 ${
                        open ? "right-0" : "right-[-800px]"
                    }`}
                >
                    <div className="navbar-ul lg:w-full hover:text-[#2A9D8F] transition-all text-center cursor-pointer px-2">
                        <NavLink className="cursor-pointer" to="/Home">
                            Home
                        </NavLink>
                    </div>
                    <div className="navbar-ul lg:w-full hover:text-[#2A9D8F] transition-all text-center cursor-pointer px-2">
                        <NavLink className="cursor-pointer" to="/AboutUs">
                            About
                        </NavLink>
                    </div>
                    <div className="navbar-ul lg:w-full hover:text-[#2A9D8F] transition-all text-center cursor-pointer px-2">
                        <NavLink className="cursor-pointer" to="/booking">
                            Book
                        </NavLink>
                    </div>
                    <div className="navbar-ul lg:w-full hover:text-[#2A9D8F] transition-all text-center cursor-pointer px-2">
                        <NavLink className="cursor-pointer" to="/ContactUs">
                            Contact
                        </NavLink>
                    </div>
                    {/* <ul className="navbar-ul lg:w-full hover:text-black transition-all text-center cursor-pointer drop-shadow-xl px-2 text-[#2A9D8F]">
                        <NavLink className="cursor-pointer" to="/LogIn">Log In</NavLink>
                    </ul> */}
                </div>
                <div
                    onClick={() => {
                        setOpen(!open);
                    }}
                    className="grid ml-auto text-2xl md:hidden place-items-center"
                >
                    <ion-icon name={open ? "close" : "menu"}></ion-icon>
                </div>
            </div>
        </div>
    );
}
