import React, { useEffect, useState } from "react";

import Nav from "../Nav";
import Banner from "./Banner";
import TourDescription from "./TourDescription";
import Package from "./Package";
import BottomBanner from "./BottomBanner";
import Footer from "./Footer";
import dataservicepost from "../../DataService";
import { dataserviceget } from "../../DataService";
import AOS from "aos";
import "aos/dist/aos.css";
import { homepageContext } from "../../App";
import { useContext } from "react";
AOS.init();

export default function Home() {
    var res;
    const data = [];
    const columns = [];
    const { homepage, setHomePage } = useContext(homepageContext);
    const [users, setUsers] = useState({
        columns,
        data,
    });
    const fetchDropdownData = () => {
        dataserviceget("dropdown", {})
            .then(function (data) {
                //
            })
            .catch(function (edata) {
                //
            });
    };
    const fetchMasterData = () => {
        dataserviceget("gettourdestination", {})
            .then(function (data) {
                if (data.resultKey == 1) {
                    let resdata = data.resultValue;
                    // let provincedata = resdata[0]["province"];
                    // let countrydata = resdata[0]["country"];
                    // let citydata = resdata[0]["city"];
                     let destinationdata = resdata;
                    // countrydata.forEach(function (element) {
                    //     element.label = element.name;
                    //     element.value = element.id;
                    // });
                    // provincedata.forEach(function (element) {
                    //     element.label = element.name;
                    //     element.value = element.id;
                    // });
                    // citydata.forEach(function (element) {
                    //     element.label = element.name;
                    //     element.value = element.id;
                    // });
                    destinationdata.forEach(function (element) {
                        element.label = element.name;
                        element.value = element.id;
                    });
                    ;
                    setHomePage((prevState) => ({
                        ...prevState,
                        fee:[],
                        touroperator:[],
                        destination: destinationdata,
                        package:[],
                        // fee: resdata[0]["fee"],
                        // destination: destinationdata,
                        // touroperator: resdata[0]["touroperatordata"],
                        // package: resdata[0]["packagedata"],
                        // country: countrydata,
                        // province: provincedata,
                        // city: citydata,
                    }));
                }
            })
            .catch(function (edata) {
                debugger;
            });
    };
    useEffect(() => {
        //   fetchDropdownData();
        fetchMasterData();
    }, []);
    useEffect(() => {
        //console.log(homepage);
    }, [homepage]);

    return (
        <div className="flex flex-col items-center">
            <Nav />
            <Banner />
            <TourDescription />
            <Package />
            <BottomBanner />
            <Footer />
            
        </div>
    );
}
